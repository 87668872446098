<template>
  <div class="flex w-min select-none items-center rounded-full bg-white">
    <UiButton
      icon="chevron-left-type-2"
      size="small"
      type="primary"
      @click="input(-1)"
    />

    <div class="mx-2 font-bold">
      {{ normalizedOptions[index]?.label ?? '' }}
    </div>

    <UiButton
      icon="chevron-right-type-2"
      size="small"
      type="primary"
      @click="input(1)"
    />
  </div>
</template>

<script setup lang="ts">
import type {FormKitFrameworkContext} from '@formkit/core';

type Option = {
  label: string
  value: number | string
}

const props = defineProps<{
  context: FormKitFrameworkContext
}>();

const node = computed(() => props.context.node);
const index = ref(0);
const options = ref<Array<number | string | Option>>(node.value.props.options ?? []);
const normalizedOptions = computed<Option[]>(() => options.value.map((option) => {
  if (typeof option !== 'object') {
    return {
      label: '' + option,
      value: option,
    };
  }

  return option;
}));

watch(
  () => props.context._value,
  (value) => {
    index.value = Math.max(0, normalizedOptions.value.findIndex(option => option.value === value));
  },
  {immediate: true},
);

function input(increment: number) {
  const newIndex = index.value + increment;

  if ((index.value + increment) > (options.value.length - 1)) {
    index.value = 0;
  } else if (newIndex < 0) {
    index.value = options.value.length - 1;
  } else {
    index.value = newIndex;
  }

  node.value.input(
    normalizedOptions.value[index.value].value,
  );
}
</script>
